<template>
  <div class='system-person mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name"><div>检索条件</div></div>
      <el-form ref="searchForm" :model="keyWord" label-width="140px" inline @submit.native.prevent size="small">
        <el-form-item label="人员名称/拼音:" prop="name">
          <el-input type="text" class="w180" v-model="keyWord.name" placeholder="请输入人员名称" clearable @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:system:person:add')"><i class="el-icon-circle-plus-outline"></i>添加人员</el-button>
          <el-button type="text" @click="openDio('batchAdd')" v-if="isAuth('safe:system:person:add')"><i class="el-icon-circle-plus-outline"></i>批量添加</el-button>
          <!-- <el-button type="text" @click="openDio('issue')" v-if="isAuth('basic:person:issue')"><i class="el-icon-download"></i>下发指纹到指纹头</el-button> -->
          <el-button type="text" class="warning" @click="batchClear()" v-if="isAdmin"><i class="el-icon-delete"></i>清除指纹</el-button>
          <el-button type="text" class="error" @click="batchDel()" v-if="isAuth('safe:system:person:del')"><i class="el-icon-delete"></i>批量删除</el-button>
        </div>
      </div>
      <div class="tableDivPage mt10">
        <el-table class="tableCont" :data="tableData" stripe :height="tableH" style="width:100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed></el-table-column>
          <el-table-column prop="personName" label="姓名"/>
          <el-table-column :formatter="formatSex" label="性别"/>
          <el-table-column prop="personNo" label="人员编号"/>
          <!-- <el-table-column :formatter="formatRank" label="军衔"/>
          <el-table-column :formatter="formatPost" label="职位"/>
          <el-table-column :formatter="formatType" label="执勤类型"/>
          <el-table-column :formatter="formatAbility" label="执勤能力"/> -->
          <el-table-column prop="nativePlace" label="籍贯"/>
          <el-table-column prop="depaName" label="企业"/>
          <el-table-column prop="account" label="关联账号"/>
          <el-table-column prop="fingerprintNum" label="指纹数量"/>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" class="primary" @click="openDio('edit',scope.row)" v-if="isAuth('safe:system:person:edit')"><i class="el-icon-edit"></i>修改</el-button>
              <!-- <el-button type="text" class="primary" @click="openDio('fingerprint',scope.row)"><i class="icon icon-scan"></i>指纹采集</el-button> -->
              <el-button type="text" class="error" @click="openDio('del',scope.row)" v-if="isAuth('safe:system:person:del')"><i class="el-icon-delete"></i>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pageDiv">
        <el-pagination
          :current-page="curr"
          :page-size="limits"
          background
          layout="total,sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100,200]"
          :total="total"
          @size-change="sizeChange"
          @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <person-add-edit ref="editDio" @addEditSucc="getList()" :dicObj="dicObj"></person-add-edit>
    <!--批量添加-->
    <person-import ref="batchAddDio" @addEditSucc="getList()" :dicObj="dicObj"></person-import>
    <!--指纹采集-->
    <!-- <person-fingerprint ref="fingerprintDio" @addEditSucc="getList(1)" @deleteSuccess="getList()"></person-fingerprint> -->
    <!--选择人员下发-->
    <!-- <tree-person-transfer ref="treeSoldierRef"></tree-person-transfer> -->
  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb'
import personAddEdit from './person-add-edit'
import personImport from './person-import'
// import soldierFingerprint from './soldier-fingerprint'
// import treeSoldierTransfer from './tree-soldier-transfer'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
var vm;
export default {
  name:'system-person',
  data() {
    return {
      //筛选数据
      keyWord :{
        name:'',
      },
      //字典数据
      dicObj :{
        abilityData:[],//执勤能力
        typeData:[],//执勤类型
        educationData:[],//教育程度
        healthData:[],//健康状况
        maritalData:[],//婚姻状况
        rankData:[],//军衔
        nationData:[],//民族
        politicalData:[],//政治面貌
        postList:[],//职务
        userList:[],//人员
      },
      //分页
      curr :1,//当前页
      limits :10,//每页容量
      total :0,//数据总数
      
      tableH :'',//表格高度
      tableActive:[],//表格选中
      tableData :[],

      isAdmin:false,//是否是超级管理员
    }
  },
  watch:{
    
  },
  components:{
    personAddEdit,
    // soldierFingerprint,
    breadCrumb,
    personImport,
    // treeSoldierTransfer,
  },
  created() {
    vm = this
    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
    vm.getCurrDics()
    vm.postListFun()
    vm.userListFun()
    vm.getList()
    vm.initHeight()
  },

  mounted(){
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //表格多选
    handleSelectionChange(val) {
      vm.tableActive = val;
    },
    //打开弹窗
    openDio (type,item){
      if(type == 'del'){
        vm.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            let arr=[]
            if(item){
              arr.push(item.id)
            }
            vm.deletePerson(arr)
          })
          .catch(() => {})
      }else{
        if(type == 'add'||type=='edit'){
          vm.$refs.editDio.init(type,item);//新增或修改
        }else if(type=='batchAdd'){
          vm.$refs.batchAddDio.init(type);//批量添加
        }
        // else if(type == 'fingerprint'){
        //   vm.$refs.fingerprintDio.init(type,item);//指纹采集
        // }else if(type == 'issue'){//下发指纹
        //   vm.$refs.treePpersonRef.init([])
        // }else if(type == 'clear'){//清除所有指纹
        //   vm.$store.state.qtClient?.slotWebClearFingerprintClick()
        // }
      }
    },
    //获取数据字典
    async getCurrDics(){
      let dataObj = ['DOMAIN_DUTY_ABILITY','DOMAIN_DUTY_TYPE','DOMAIN_EDUCATION_LEVEL','DOMAIN_HEALTH_STATUS','DOMAIN_MARITAL_STATUS','DOMAIN_MILITARY_RANK','DOMAIN_NATION','DOMAIN_POLITICAL_STATUS']
      const res = await apiUrl.getDicts(dataObj)
      if(res?.code==200){
        vm.dicObj.abilityData = res.data.DOMAIN_DUTY_ABILITY||[];//执勤能力
        vm.dicObj.typeData = res.data.DOMAIN_DUTY_TYPE||[];//执勤类型
        vm.dicObj.educationData = res.data.DOMAIN_EDUCATION_LEVEL||[];//教育程度
        vm.dicObj.healthData = res.data.DOMAIN_HEALTH_STATUS||[];//健康状况
        vm.dicObj.maritalData = res.data.DOMAIN_MARITAL_STATUS||[];//婚姻状况
        vm.dicObj.rankData = res.data.DOMAIN_MILITARY_RANK||[];//军衔
        vm.dicObj.nationData = res.data.DOMAIN_NATION||[];//民族
        vm.dicObj.politicalData = res.data.DOMAIN_POLITICAL_STATUS||[];//政治面貌
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取职务列表
    async postListFun(){
      const res = await apiUrl.postList({})
      if(res?.code==200){
        vm.dicObj.postList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //清除指纹
    batchClear(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      let arr = []
      for(var i in vm.tableActive){
        arr.push(vm.tableActive[i].id)
      }
      vm.deleteByPersonIds(arr)
      //do something
    },
    //根据人员id清除指纹
    async deleteByPersonIds(ids){
      let dataObj = {
        'data':ids
      }
      const res = await apiUrl.deleteByPersonIds(dataObj)
      if(res?.code==200){
        vm.$message.success('清除成功')
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //批量删除
    batchDel(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        for(var i in vm.tableActive){
          arr.push(vm.tableActive[i].id)
        }
        vm.deletePerson(arr);//删除
      }).catch(() => {});
    },
    //删除人员
    async deletePerson(ids){
      let dataObj = {
        'data':ids
      }
      const res = await apiUrl.deletePerson(dataObj)
      if(res?.code==200){
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //过滤性别
    formatSex (row, column){
      return row.gender==1?'男':
            row.gender==2?'女':'-'
    },
    //过滤军衔
    formatRank (row, column){
      let result = vm.dicObj.rankData.filter(item => item.dictValue == row.militaryRank)
      return result!='' ? result[0].dictName :'-'
    },
    //过滤职务
    formatPost (row, column){
      let result = []
      if(row.posts?.length>0){
        for(let i in row.posts){
          result.push(row.posts[i].postName) 
        }
      }
      return result!='' ? result.join('、') :'-'
    },
    //过滤执勤类型
    formatType (row, column){
      let result = vm.dicObj.typeData.filter(item => item.dictValue == row.dutyType)
      return result!='' ? result[0].dictName :'-'
    },
    //过滤执勤能力
    formatAbility(row, column){
      let result = vm.dicObj.abilityData.filter(item => item.dictValue == row.dutyAbility)
      return result!='' ? result[0].dictName :'-'
    },
    //获取用户列表
    async userListFun(){
      const res = await apiUrl.userList({})
      if(res?.code==200){
        vm.dicObj.userList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //分页选择
    sizeChange (val){
      vm.limits = val
      vm.getList(vm.curr,val)
    },
    currChange(val) {
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList (_curr,_limits){
      let dataObj = {
        'keyword': vm.keyWord.name,
        'pageNo': _curr?_curr:vm.curr,
        'pageSize': _limits?_limits:vm.limits
      }
      const res = await apiUrl.personListPage(dataObj)
      if(res?.code==200){
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr?_curr:vm.curr
      }else{
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-322
    }
  }
}
</script>
<style lang='scss'>
.system-person{

}
</style>
