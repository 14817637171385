<template>
  <div class="personImport" v-if="isDio">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio()">
      <div class="contDio">
        <el-button type="warning" size="small" @click="exportDepotTemplate()">下载模板文件</el-button>
        <el-form class="mt20" ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-form-item label="企业:" prop="depaId">
            <el-cascader v-model="inform.depaId" :options="departList" :props="cascProps" :show-all-levels="false"/>
          </el-form-item>
        </el-form>
        <div class="tc mg10">
          <el-upload
            class="upload-demo"
            drag
            action="#"
            ref="upload"
            :limit="1"
            :on-remove="handleRemove"
            :http-request="httpRequest"
            :before-upload="beforeUpload">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="closeDio()" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
var vm;
export default {
  props:[],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        depaId:'',//企业IDs
        importFile:'',
      },
      departList :[],//企业数据
      cascProps :{
        value:'id',
        label:'depaName',
        children:'childDepartments',
        checkStrictly: true,
        emitPath:false,
      },
      
      rules :{
        depaId: [
          { required: true, message: '请选择企业', trigger: "change" }
        ],
      },
    }
  },
  components:{
    
  },
  created(){
    vm = this
    
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    init (type){
      vm.dioType = type
      vm.getDepartmentTree()
      vm.dioWidth = '540px'
      vm.dioTit = '批量添加'
      vm.isDio = true
      
    },
    //获取企业树列表
    async getDepartmentTree (){
      const res = await apiUrl.getDepartmentTree()
      if(res?.code==200){
        vm.departList = res.data
        delChildren(vm.departList)
      }else{
        vm.$message.error(res.message)
      }
    },
    //清除
    handleRemove(file, fileList) {
      console.log(file, fileList);
      vm.inform.importFile = ''
    },
    // 上传之前的格式设置
    beforeUpload (file){
      // const isExcel = /\.(xls|xlsx)$/.test(file.name);
      // if (!isExcel) {
      //   this.$message.error('只能上传 Excel 文件!');
      // }
      // return isExcel;

      const isExcel=file.type==='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'||file.type === 'application/vnd.ms-excel';
      if (!isExcel) {
        vm.$message.warning('上传文件只能是 Excel 格式!');
        return false;
      }
      return true;
    },
    httpRequest (data){
      console.log(data);
      vm.inform.importFile = data.file
      // vm.inform.logoFile = data.file
      // let rd =new FileReader()// 创建文件读取对象
      // let file = data.file
      // // vm.inform.headPic = file
      // rd.readAsBinaryString(file)// 文件读取装换为二进制类型
      // rd.readAsDataURL(file)// 文件读取装换为base64类型
      // rd.onloadend =function (e) {
      //   // console.log(e);
      //   vm.inform.logoPicImg = this.result// this指向当前方法onloadend的作用域
      // }
    },
    //提交
    checkForm(formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if (vm.inform.importFile) {
            // 触发自定义上传
            vm.personUpload()
          }
        } else {return false}
      })
      
    },
    //导入
    async personUpload(){
      let dataObj = new FormData();
      dataObj.append("departId ", vm.inform.depaId);
      dataObj.append("file ", vm.inform.importFile);
      const res = await apiUrl.personUpload(dataObj)
      if(res?.code==200){
        this.$message.success('批量添加成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        // this.$message.error(res.message)
        this.$message({
          type:'error',
          dangerouslyUseHTMLString: true,
          message: res.message,
          duration:10000,
          showClose:true,
        });
      }
    },
    //导出
    async exportDepotTemplate(){
      const res = await apiUrl.templateFileDownload('PERSON_EXCLE');//PERSON_EXCLE:人员模板
      if(!res) return;
      //fileName:下载的文件名
      var fileName = decodeURIComponent(res.headers["content-disposition"].split("=")[1])
      let blob = new Blob([res.data], {type: res.headers['content-type']});
      
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob); //常见下载的链接
      downloadElement.href = href;
      downloadElement.download = fileName; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
    },
    //关闭弹窗
    closeDio (formEl){
      vm.inform.importFile = ''
      vm.inform.depaId = ''
      vm.departList = []
      vm.isDio = false
    },
    
  }
}
</script>
<style lang='scss'>
.personImport{
  .contDio{
    max-height:500px;
    overflow:auto;
    .upload-demo{
      .el-upload-dragger{
        background-color: #031e44;
        border-color: #1d4975;
        color: #fff;
        .el-icon-upload{
          color: #fff;
        }
        .el-upload__text{
          color: #fff;
        }
      }
      .el-upload-list__item{
        color: #fff;
        .el-upload-list__item-name,.el-icon-close{
          color: #fff;
        }
        &:hover{
          background-color: #1d4975;
        }
      }
      
    }
    
  }
}
</style>