<template>
  <div class="personAddEdit" v-if="isDio">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio h500">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-row :gutter="30">
            <el-col :span="12">
            <el-form-item label="头像:" prop="headPicImg">
              <el-upload
                class="uploader"
                action="#"
                :show-file-list="false"
                :http-request="httpRequest"
                :before-upload="beforeUpload"
              >
                <img v-if="inform.headPicImg" :src="inform.headPicImg" class="img">
                <i v-else class="iconJia el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="关联用户:" prop="userId">
              <el-select v-model="inform.userId" clearable filterable placeholder="请选择关联用户">
                <el-option v-for="item in dicObj.userList" :key="item.id" :label="item.account" :value="item.id"/>
              </el-select>
            </el-form-item>
            <el-form-item label="籍贯:" prop="nativePlace">
              <el-input type="text" v-model="inform.nativePlace" placeholder="请输入籍贯"></el-input>
            </el-form-item>
            <el-form-item label="个人手机:" prop="phone">
              <el-input type="text" v-model="inform.phone" placeholder="请输入个人手机号"></el-input>
            </el-form-item>
            <el-form-item label="企业:" prop="depaId">
              <el-cascader v-model="inform.depaId" :options="departList" :props="cascProps" :show-all-levels="false"/>
            </el-form-item>
            <el-form-item label="身体状况:" prop="healthStatus">
              <el-select v-model="inform.healthStatus" placeholder="请选择身体状况">
                <el-option v-for="item in dicObj.healthData" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"/>
              </el-select>
            </el-form-item>
            <el-form-item label="婚姻状况:" prop="maritalStatus">
              <el-select v-model="inform.maritalStatus" placeholder="请选择婚姻状况">
                <el-option v-for="item in dicObj.maritalData" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"/>
              </el-select>
            </el-form-item>
            <el-form-item label="文化程度:" prop="education">
              <el-select v-model="inform.education" placeholder="请选择文化程度">
                <el-option v-for="item in dicObj.educationData" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"/>
              </el-select>
            </el-form-item>
            </el-col>
            <el-col :span="12">
            <el-form-item label="身高(CM):" prop="height">
              <el-input type="number" :min="0" v-model="inform.height" placeholder="请输入身高"></el-input>
            </el-form-item>
            <el-form-item label="体重(KG):" prop="weight">
              <el-input type="number" :min="0" :max="999" v-model="inform.weight" placeholder="请输入体重"></el-input>
            </el-form-item>
            <el-form-item label="在位情况:" prop="reignStatus">
              <el-select v-model="inform.reignStatus" placeholder="请选择在位情况">
                <el-option label="不在位" :value="0"/>
                <el-option label="在位" :value="1"/>
              </el-select>
            </el-form-item>
            <el-form-item label="姓名:" prop="personName">
              <el-input type="text" v-model="inform.personName" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="性别:" prop="gender">
              <el-select v-model="inform.gender" placeholder="请选择性别">
                <el-option label="男" :value="1"/>
                <el-option label="女" :value="2"/>
              </el-select>
            </el-form-item>
            <el-form-item label="出生日期:" prop="birthDate">
              <el-date-picker v-model="inform.birthDate" value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="请选择出生日期" />
            </el-form-item>
            <el-form-item label="人员编号:" prop="personNo">
              <el-input type="text" v-model="inform.personNo" placeholder="请输入人员编号"></el-input>
            </el-form-item>
            <!-- <el-form-item label="职务:" prop="postIds">
              <el-select v-model="inform.postIds" placeholder="请选择职务(多个)" multiple collapse-tags>
                <el-option v-for="item in dicObj.postList" :key="item.id" :label="item.postName" :value="item.id"/>
              </el-select>
            </el-form-item>
            <el-form-item label="军衔:" prop="militaryRank">
              <el-select v-model="inform.militaryRank" placeholder="请选择军衔">
                <el-option v-for="item in dicObj.rankData" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"/>
              </el-select>
            </el-form-item>
            <el-form-item label="执勤类型:" prop="dutyType">
              <el-select v-model="inform.dutyType" placeholder="请选择执勤类型">
                <el-option v-for="item in dicObj.typeData" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"/>
              </el-select>
            </el-form-item>
            <el-form-item label="执勤能力:" prop="dutyAbility">
              <el-select v-model="inform.dutyAbility" placeholder="请选择执勤能力">
                <el-option v-for="item in dicObj.abilityData" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"/>
              </el-select>
            </el-form-item> -->
            <el-form-item label="民族:" prop="nation">
              <el-select v-model="inform.nation" placeholder="请选择民族">
                <el-option v-for="item in dicObj.nationData" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"/>
              </el-select>
            </el-form-item>
            <el-form-item label="政治面貌:" prop="politicalStatus">
              <el-select v-model="inform.politicalStatus" placeholder="请选择政治面貌">
                <el-option v-for="item in dicObj.politicalData" :key="item.dictValue" :label="item.dictName" :value="item.dictValue"/>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="入伍时间:" prop="enlistTime">
              <el-date-picker v-model="inform.enlistTime" value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="请选择入伍时间" />
            </el-form-item> -->
            <el-form-item label="家庭联系电话:" prop="homeTelephone">
              <el-input type="text" v-model="inform.homeTelephone" placeholder="请输入家庭联系电话"></el-input>
            </el-form-item>
            <!-- <el-form-item label="入党(团)时间:" prop="joinPartyTime">
              <el-date-picker v-model="inform.joinPartyTime" value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="请选择入党(团)时间" />
            </el-form-item> -->
            </el-col>
          </el-row>

        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatTime,delChildren } from '@/utils/utils'
import { apiUrl,globalStr } from '@/assets/js/api'
var vm;
export default {
  props:['dicObj'],
  data() {
    const checkWeight = (rule,value,callback)=>{
      if(value<0 ||value > 999){
        callback(new Error('请输入合理的体重值'))
      }else{
        callback()
      }
    }
    const checkHeight = (rule,value,callback)=>{
      if(value<0 ||value > 999){
        callback(new Error('请输入合理的身高'))
      }else{
        callback()
      }
    }
    return {

      isDio :false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        headPicImg:'',//头像显示
        headPic:'',//头像文件
        nativePlace:'',//籍贯
        healthStatus:'',//身体状况
        maritalStatus:'',//婚姻状况
        education:'',//文化程度
        phone:'',//个人手机
        height:'',//身高
        weight:'',//体重
        reignStatus:'',//在位情况
        depaId:'',//企业IDs
        postIds:[],//职务IDs
        userId:'',//关联用户Id
        militaryRank:'',//军衔
        personName:'',//姓名
        personNo:'',//人员编号
        gender:'',//性别
        dutyType:'',//执勤类型
        dutyAbility:'',//执勤能力
        nation:'',//民族
        birthDate:'',//出生日期
        politicalStatus:'',//政治面貌
        enlistTime:'',//入伍时间
        homeTelephone:'',//家庭联系电话
        joinPartyTime:'',//入党（团）时间
      },
      departList :[],//企业数据
      cascProps :{
        value:'id',
        label:'depaName',
        children:'childDepartments',
        checkStrictly: true,
        emitPath:false,
      },

      //验证form格式
      rules :{
        // nativePlace: [
        //   { required: true, message: '请输入籍贯', trigger: 'blur' },
        // ],
        personName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'chenge' },
        ],
        // birthDate: [
        //   { required: true, message: '请选择出生日期', trigger: 'change' },
        // ],
        weight: [
          { validator:checkWeight, trigger: 'blur' },
        ],
        height: [
          { validator:checkHeight, trigger: 'blur' },
        ],
        depaId: [
          { required: true, message: '请选择企业', trigger: "change" }
        ],
        phone: [
          { required: false, message: '请输入手机号', trigger: 'blur' },
          { pattern :/^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/, message:'请输入11位正确的手机号'}
        ],
      },
    }
  },
  created(){
    vm = this;
  },
  mounted(){
    
  },

  //方法
  methods:{
    //初始化
    init (type,item){
      vm.dioType = type
      vm.dioWidth = '840px'
      vm.isDio = true
      vm.getDepartmentTree()
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '创建人员'
        }else if(type == 'edit'){
          vm.dioTit = '编辑人员'
          let postArr = item.posts?.map(value=>{
            return value.id;
          })
          vm.inform.depaId = item.depaId//企业
          vm.inform.postIds = postArr//职务
          vm.inform.id = item.id
          vm.inform.nativePlace=item.nativePlace//籍贯
          vm.inform.headPicImg=`${globalStr}/fileView${item.headPicInfo?.fileRelativePath}`//显示头像
          vm.inform.healthStatus=item.healthStatus//身体状况
          vm.inform.maritalStatus=item.maritalStatus//婚姻状况
          vm.inform.education=item.education//文化程度
          vm.inform.phone=item.phone//个人手机
          vm.inform.height=item.height//身高
          vm.inform.weight=item.weight//体重
          vm.inform.reignStatus=item.reignStatus//在位情况
          vm.inform.userId=item.userId//关联用户Id
          vm.inform.militaryRank=item.militaryRank//军衔
          vm.inform.personName=item.personName//姓名
          vm.inform.personNo=item.personNo//人员编号
          vm.inform.gender=item.gender//性别
          vm.inform.dutyType=item.dutyType//执勤类型
          vm.inform.dutyAbility=item.dutyAbility//执勤能力
          vm.inform.nation=item.nation//民族
          vm.inform.birthDate=formatTime(item.birthDate,'Y-M-D h:m:s')//出生日期
          vm.inform.politicalStatus=item.politicalStatus//政治面貌
          vm.inform.enlistTime=formatTime(item.enlistTime,'Y-M-D h:m:s')//入伍时间
          vm.inform.homeTelephone=item.homeTelephone//家庭联系电话
          vm.inform.joinPartyTime=formatTime(item.joinPartyTime,'Y-M-D h:m:s')//入党（团）时间
        }
      })
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.addPersonInsert()
          }else if(vm.dioType=='edit'){
            vm.updatePerson()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.isDio = false
    },
    // 上传之前的格式设置
    beforeUpload (file){
      // const isJPG = file.type ==='image/jpeg'
      const isPNG = file.type ==='image/png' || file.type === 'image/jpg'||file.type === 'image/jpeg'
      const isLt8M = file.size / 1024 / 1024 < 8
      if (!isPNG) {
        vm.$message.warning('上传头像图片只能是JPG、JPEG或PNG 格式!')
      }
      if (!isLt8M) {
        vm.$message.warning('上传头像图片大小不能超过 8MB!')
      }
      return isPNG && isLt8M
    },
    httpRequest (data){
      // console.log(data);
      vm.inform.headPic = data.file
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      // // vm.inform.headPic = file
      // rd.readAsBinaryString(file)// 文件读取装换为二进制类型
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        // console.log(e);
        vm.inform.headPicImg = this.result// this指向当前方法onloadend的作用域
      }
    },
    //获取企业树列表
    async getDepartmentTree (){
      const res = await apiUrl.getDepartmentTree()
      if(res?.code==200){
        vm.departList = res.data
        delChildren(vm.departList)
      }else{
        vm.$message.error(res.message)
      }
    },
    //新增
    async addPersonInsert (){
      let dataObj = new FormData();
      if(vm.inform.headPic){
        dataObj.append("headPic", vm.inform.headPic);
      }
      dataObj.append("personName", vm.inform.personName||'');
      dataObj.append("nativePlace", vm.inform.nativePlace||'');
      dataObj.append("gender", vm.inform.gender||'');
      dataObj.append("birthDate", vm.inform.birthDate||'');
      dataObj.append("depaId", vm.inform.depaId.split(','));
      dataObj.append("phone", vm.inform.phone||'');
      dataObj.append("healthStatus", vm.inform.healthStatus||'');
      dataObj.append("maritalStatus", vm.inform.maritalStatus||'');
      dataObj.append("education", vm.inform.education||'');
      dataObj.append("height", vm.inform.height||'');
      dataObj.append("weight", vm.inform.weight||'');
      if(vm.inform.reignStatus){
        dataObj.append("reignStatus", vm.inform.reignStatus);
      }
      dataObj.append("postIds", vm.inform.postIds||'');
      dataObj.append("userId", vm.inform.userId||'');
      dataObj.append("militaryRank", vm.inform.militaryRank||'');
      dataObj.append("personNo", vm.inform.personNo||'');
      dataObj.append("dutyType", vm.inform.dutyType||'');
      dataObj.append("dutyAbility", vm.inform.dutyAbility||'');
      dataObj.append("nation", vm.inform.nation||'');
      dataObj.append("politicalStatus", vm.inform.politicalStatus||'');
      dataObj.append("enlistTime", vm.inform.enlistTime||'');
      dataObj.append("homeTelephone", vm.inform.homeTelephone||'');
      dataObj.append("joinPartyTime", vm.inform.joinPartyTime||'');
      
      const res = await apiUrl.addPersonInsert(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updatePerson (){
      let dataObj = new FormData();
      dataObj.append("id", vm.inform.id);
      if(vm.inform.headPic){
        dataObj.append("headPic", vm.inform.headPic);
      }
      dataObj.append("personName", vm.inform.personName||'');
      dataObj.append("nativePlace", vm.inform.nativePlace||'');
      dataObj.append("gender", vm.inform.gender||'');
      dataObj.append("birthDate", vm.inform.birthDate||'');
      dataObj.append("depaId", vm.inform.depaId.split(','));
      dataObj.append("phone", vm.inform.phone||'');
      dataObj.append("healthStatus", vm.inform.healthStatus||'');
      dataObj.append("maritalStatus", vm.inform.maritalStatus||'');
      dataObj.append("education", vm.inform.education||'');
      dataObj.append("height", vm.inform.height||'');
      dataObj.append("weight", vm.inform.weight||'');
      if(vm.inform.reignStatus){
        dataObj.append("reignStatus", vm.inform.reignStatus);
      }
      dataObj.append("postIds", vm.inform.postIds||'');
      dataObj.append("userId", vm.inform.userId||'');
      dataObj.append("militaryRank", vm.inform.militaryRank||'');
      dataObj.append("personNo", vm.inform.personNo||'');
      dataObj.append("dutyType", vm.inform.dutyType||'');
      dataObj.append("dutyAbility", vm.inform.dutyAbility||'');
      dataObj.append("nation", vm.inform.nation||'');
      dataObj.append("politicalStatus", vm.inform.politicalStatus||'');
      dataObj.append("enlistTime", vm.inform.enlistTime||'');
      dataObj.append("homeTelephone", vm.inform.homeTelephone||'');
      dataObj.append("joinPartyTime", vm.inform.joinPartyTime||'');
      const res = await apiUrl.updatePerson(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.personAddEdit{
  .h500{
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .uploader{
    .el-upload {
      border: 1px dashed #1d4975;
      background-color: #031e44;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      &:hover {
        border-color: #409EFF;
      }
      .iconJia{
        font-size: 28px;
        color: #fff;
        width: 88px;
        height: 116px;
        line-height: 116px;
        text-align: center;
      }
      .img {
        width: 88px;
        height: 116px;
        display: block;
      }
    }
  }
}
</style>